import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Alert,
  Button,
  Box,
  CircularProgress,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import SimCardIcon from "@mui/icons-material/SimCard";
import CloseIcon from "@mui/icons-material/Close";

import { useOrganization } from "../..";
import { useSnackbar } from "../../../../../contexts/SnackbarContext";
import { fetchData } from "../../../../../fetchData";

import SingleFileUpload from "../../../../../components/SingleFileUpload";

import { formatDate } from "../../../../../utils";

const steps = ["Senha", "Upload", "Certificado"];

type FormValues = {
  password: string;
};

const DigitalCertificate = () => {
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [progress, setProgress] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorStep, setErrorStep] = useState<any>(null);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      password: organization.digital_certificate?.password || "",
    },
  });

  async function onSubmit(data: any) {
    setProgress(true);
    const payload = {
      ...organization,
      digital_certificate: { ...organization.digital_certificate, ...data },
    };

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      set(payload);
      setActiveStep(1);
      setValue("password", "");
      snackbar?.open("Senha do certificado salva com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível salvar a senha.",
        "error",
      );
    }

    setProgress(false);
  }

  const handleError = (msg: string) => {
    snackbar?.open(msg, "error");
  };

  const updateOrganization = async () => {
    try {
      const data = await fetchData(`/organizations/${organization._id}`, {
        method: "GET",
      });

      set(data);
      if (!data.digital_certificate.state) setErrorStep(2);
      setProgress(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async (key: string, name: string) => {
    try {
      fetchData(
        `/cloud/download?key=${key}`,
        {
          method: "GET",
        },
        false,
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.download = name + ".pfx";

          document.body.appendChild(link);

          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          document.body.removeChild(link);
        })
        .catch((error) => {
          handleError("Ops! No momento não foi possível baixar o certificado.");
        });
    } catch (error) {
      handleError("Ops! No momento não foi possível baixar o certificado.");
    }
  };

  const handleResetProcess = async () => {
    setActiveStep(0);
    setDeleteFile(false);
    setErrorStep(null);

    const data = organization;

    data.digital_certificate = {};

    await fetchData(`/organizations/${organization._id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    set(data);
  };

  const handleDelete = async (key: string) => {
    try {
      fetchData(
        `/cloud?key=${key}`,
        {
          method: "DELETE",
        },
        false,
      )
        .then((response) => {
          snackbar?.open("Certificado excluído com sucesso!", "success");
          handleResetProcess();
        })
        .catch((error) => {
          handleError("Ops! Não foi possível excluir o certificado.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível excluir o certificado.");
    }
  };

  const handleLinkCopy = async (key: string) => {
    try {
      fetchData(`/cloud/share?key=${key}`, {
        method: "GET",
      })
        .then((response) => {
          navigator.clipboard.writeText(response.url);
          snackbar?.open("Link copiado com sucesso!", "success");
        })
        .catch((error) => {
          handleError("Ops! Não foi possível gerar o link.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível gerar o link.");
    }
  };

  useEffect(() => {
    setErrorStep(null);
    setDeleteFile(false);

    if (
      organization.digital_certificate?.password &&
      organization.digital_certificate?.state === undefined
    ) {
      setActiveStep(1);
    } else if (
      organization.digital_certificate?.password &&
      organization.digital_certificate?.state !== undefined
    ) {
      setActiveStep(2);
      if (!organization.digital_certificate?.state) setErrorStep(2);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization._id]);

  const onUploadComplete = (response: any) => {
    setProgress(true);
    setActiveStep(2);
    setTimeout(() => updateOrganization(), 5000);
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
      >
        <SimCardIcon sx={{ mr: 1 }} /> Certificado Digital
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <SimCardIcon />
              <Typography
                variant="body2"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Certificado Digital
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setState(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
            {steps.map((label, key) => (
              <Step key={label} last={steps.length === key + 1 ? true : false}>
                <StepLabel error={errorStep === key}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register("password")}
                label="Senha do certificado"
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
              />
              {errors?.password && (
                <Alert severity="error">
                  {errors.password.message?.toString()}
                </Alert>
              )}
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 1 }}
              >
                {!progress && "Continuar"}
                {progress && (
                  <CircularProgress size={24} sx={{ color: "#FFF" }} />
                )}
              </Button>
            </form>
          )}
          {activeStep === 1 && (
            <>
              <SingleFileUpload
                baseUrl={`cloud/${organization._id}?path=cloud/certificados`}
                allowedFiles=".pfx"
                callback={onUploadComplete}
              />
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ mt: 1 }}
                onClick={() => {
                  setActiveStep(0);
                }}
              >
                Voltar
              </Button>
            </>
          )}
          {activeStep === 2 && progress && (
            <Box sx={{ textAlign: "center", pt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {activeStep === 2 && !progress && (
            <Box>
              {!organization.digital_certificate?.state && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Não foi possível ler o arquivo PFX.
                </Alert>
              )}
              {organization.digital_certificate?.state && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Stack direction="column">
                    <Typography variant="caption" fontWeight="bold">
                      Validade do certificado
                    </Typography>
                    <Typography variant="caption">
                      {formatDate(organization.digital_certificate?.expiricy)}
                    </Typography>
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                  <Stack direction="column" spacing={2}>
                    <Button
                      variant="outlined"
                      fullWidth
                      size="small"
                      onClick={() => {
                        handleDownload(
                          organization.digital_certificate?.key,
                          `${organization.reference}_${organization.name}`,
                        );
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      fullWidth
                      size="small"
                      onClick={() => {
                        handleLinkCopy(organization.digital_certificate?.key);
                      }}
                    >
                      Copiar Link
                    </Button>
                  </Stack>
                </>
              )}
              <Divider sx={{ my: 2 }} />
              {!deleteFile && (
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  size="small"
                  onClick={() => {
                    setDeleteFile(true);
                  }}
                >
                  Apagar arquivo
                </Button>
              )}
              {deleteFile && (
                <Stack direction="column" spacing={1}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    Confirme a exclusão do arquivo?
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      size="small"
                      onClick={() => {
                        setDeleteFile(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      size="small"
                      onClick={() => {
                        handleDelete(organization.digital_certificate?.key);
                      }}
                    >
                      Confirmar
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default DigitalCertificate;
