import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Typography,
} from "@mui/material";
import DataGrid from "../../../components/DataGrid";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";
import { useAuth } from "../../../contexts/AuthContext";

const Messaging = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [messaging, setMessaging] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/messaging?limit=${limit}&skip=${skip}&order=desc`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setMessaging(
          result.rows.map((row: any) => ({
            id: row._id,
            title: row.title,
            time_mode: row.time_mode,
            state: row.state,
            created_at: formatDate(row.created_at, "D/M/Y H:i"),
            sent_at: row.sent_at ? formatDate(row.sent_at, "D/M/Y H:i") : "",
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Comunicado",
      flex: 1,
      renderCell: (params: any) => {
        const ChipComponent = () => (
          <Chip
            color={params.row.state ? "success" : "default"}
            label={params.row.state ? "Enviado" : "Rascunho"}
            size="small"
            sx={{ textTransform: "uppercase", ml: 1 }}
          />
        );

        if (auth?.user.permission_level !== "manager" && !params.row.state) {
          return (
            <>
              {params.row.title}
              <ChipComponent />
            </>
          );
        }

        return (
          <>
            <Link
              onClick={(e) => {
                e.preventDefault();

                navigate(`/messaging/${params.id}`);
              }}
              sx={{
                overflow: "hidden",
                justifyContent: "left",
                cursor: "pointer",
              }}
            >
              {params.row.title}
            </Link>
            <ChipComponent />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Data",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Stack direction="column">
            <>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {!params.row.state && "Criado em"}
                {params.row.state && "Enviado em"}
              </Typography>
              <Typography variant="body2" component="div">
                {!params.row.state && params.row.created_at}
                {params.row.state && params.row.sent_at}
              </Typography>
            </>
          </Stack>
        );
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Comunicados"
      icon={<CampaignOutlinedIcon sx={{ mr: 2 }} />}
    >
      <DataGrid
        rows={messaging}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
    </ViewContent>
  );
};

export default Messaging;
