import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Unstable_Grid2 as Grid,
  Box,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import ChurchIcon from "@mui/icons-material/Church";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import { useAuth } from "../../../contexts/AuthContext";
import { fetchData } from "../../../fetchData";

import RRDeliveredTasks from "./drawers/rrDelivered";
import RRTaskLog from "./drawers/rrTaskLog";
import RRTaskNotify from "./drawers/rrTaskNotify";
import { handleScoreColor } from "../../../utils";
import NpsProgressBox from "../../../components/NpsProgressBox";
import A1Expiricy from "./drawers/A1Expiricy";

interface A1Data {
  total: number;
  data: any[];
}

const A1ExpiricyCheck = (organization: any) => {
  if (!organization.digital_certificate?.expiricy) return true;

  const now = new Date();
  const verify = new Date(organization.digital_certificate?.expiricy);
  verify.setMonth(verify.getMonth() - 2);

  if (now < verify) return false;

  return true;
};

const NumCard = ({
  title,
  number,
  percent,
  a1,
  action = null,
  loading,
}: {
  title: string;
  number: number;
  percent: number;
  a1?: any;
  action?: any;
  loading: boolean;
}) => {
  return (
    <Card sx={{ flex: 1, height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Stack direction="row" spacing={1} sx={{ height: "100%" }}>
          <Box
            sx={{
              width: 5,
              height: `${percent}%`,
              borderRadius: 5,
              backgroundColor: "primary.main",
            }}
          ></Box>
          <Stack flex={1} justifyContent="space-between">
            {loading && <CircularProgress size={32} />}
            {!loading && (
              <div>
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {title}
                </Typography>
                <Typography variant="h4" component="div">
                  {number}
                </Typography>
                {a1 && a1.total > 0 && (
                  <A1Expiricy total={a1.total} data={a1.data} />
                )}
              </div>
            )}
            {action && action}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [report, setReport] = useState<any>({
    loading: true,
    data: [],
    id: "",
    created_at: "",
  });
  const [logID, setLogID] = useState<number | string>("");
  const [graph, setGraph] = useState({
    state: true,
    total: 0,
    empresas: { total: 0, percentual: 0, a1: {} },
    igrejas: { total: 0, percentual: 0, a1: {} },
    save: { total: 0, percentual: 0, a1: {} },
    terceiroSetor: { total: 0, percentual: 0, a1: {} },
  });
  const [photo, setPhoto] = useState({
    state: false,
    url: "",
  });

  const initPhoto = async () => {
    const response = await fetch(
      `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=business&image_type=photo&pretty=true&per_page=100&orientation=horizontal`,
    );

    if (response.ok) {
      response.json().then((result) => {
        setPhoto({
          ...photo,
          url: result.hits[Math.floor(Math.random() * 100)].largeImageURL,
        });
      });
    }
  };

  const initOrganizations = async () => {
    const result = await fetchData(`/organizations?limit=1000&status=true`, {
      method: "GET",
    });

    if (result) {
      let empresas = 0,
        igrejas = 0,
        save = 0,
        terceiroSetor = 0;

      const a1Empresas: A1Data = { total: 0, data: [] },
        a1Igrejas: A1Data = { total: 0, data: [] },
        a1Save: A1Data = { total: 0, data: [] },
        a1TerceiroSetor: A1Data = { total: 0, data: [] };

      result.rows.map((organization: any) => {
        if (organization.profile && organization.profile.includes("Empresas")) {
          empresas++;

          if (A1ExpiricyCheck(organization)) {
            a1Empresas.total = a1Empresas.total + 1;
            a1Empresas.data.push(organization);
          }
        }
        if (organization.profile && organization.profile.includes("Igrejas")) {
          igrejas++;

          if (A1ExpiricyCheck(organization)) {
            a1Igrejas.total = a1Igrejas.total + 1;
            a1Igrejas.data.push(organization);
          }
        }

        if (organization.profile && organization.profile.includes("Save")) {
          save++;

          if (A1ExpiricyCheck(organization)) {
            a1Save.total = a1Save.total + 1;
            a1Save.data.push(organization);
          }
        }

        if (
          organization.profile &&
          organization.profile.includes("Terceiro Setor")
        ) {
          terceiroSetor++;

          if (A1ExpiricyCheck(organization)) {
            a1TerceiroSetor.total = a1TerceiroSetor.total + 1;
            a1TerceiroSetor.data.push(organization);
          }
        }
      });

      setGraph({
        state: false,
        total: result.count,
        empresas: {
          total: empresas,
          percentual: Math.floor((empresas / result.count) * 100),
          a1: a1Empresas,
        },
        igrejas: {
          total: igrejas,
          percentual: Math.floor((igrejas / result.count) * 100),
          a1: a1Igrejas,
        },
        save: {
          total: save,
          percentual: Math.floor((save / result.count) * 100),
          a1: a1Save,
        },
        terceiroSetor: {
          total: terceiroSetor,
          percentual: Math.floor((terceiroSetor / result.count) * 100),
          a1: a1TerceiroSetor,
        },
      });
    }
  };

  const initNps = async () => {
    const lastOne = await fetchData(`/surveys/nps?limit=1&order=desc`, {
      method: "GET",
    });

    if (lastOne) {
      try {
        const response = await fetchData(`/surveys/nps/report`, {
          method: "POST",
          body: JSON.stringify({
            npsId: lastOne.rows[0]._id,
            filter: "alltime",
          }),
        });

        if (response) {
          setReport({
            loading: false,
            data: response,
            id: lastOne.rows[0]._id,
            created_at: lastOne.rows[0].created_at,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    initPhoto();
    initOrganizations();
    initNps();
  }, []);

  const onLoad = () => {
    setPhoto({ ...photo, state: true });
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      spacing={2}
      sx={{ height: "calc(100% - 72px)" }}
    >
      <Box sx={{ height: "350px" }}>
        <Card sx={{ height: "100%" }}>
          <CardContent
            sx={{
              p: 0,
              paddingBottom: "0 !important",
              height: "100%",
              position: "relative",
            }}
          >
            {photo.state && (
              <Stack
                sx={{
                  p: 3,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  background:
                    "linear-gradient(180deg, rgba(0,0,0,0) 42%, rgba(0,0,0,0.8) 100%)",
                }}
                direction="column"
                justifyContent="flex-end"
                spacing={2}
              >
                <Typography variant="h3" color="#FFF" fontWeight={300}>
                  Olá, {auth?.user.nickname}.
                </Typography>
              </Stack>
            )}
            <img
              src={photo.url}
              onLoad={onLoad}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: !photo.state ? "none" : "flex",
              }}
            />
            {!photo.state && (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid xs={12} lg={4} sx={{ height: "100%" }}>
            <Card sx={{ height: "100%" }}>
              {report.loading && (
                <Skeleton variant="rectangular" width="100%" height="100%" />
              )}
              {!report.loading && (
                <CardContent sx={{ height: "100%" }}>
                  <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Chip
                        label="Score NPS"
                        variant="outlined"
                        onClick={() => {
                          navigate(`/surveys/nps/${report.id}`);
                        }}
                      />
                      <Typography variant="body2">
                        {new Date(report.created_at).getFullYear()}
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 150,
                        height: 150,
                        borderRadius: 150,
                        borderWidth: 7,
                        borderStyle: "solid",
                        borderColor: handleScoreColor(
                          report.data.graph?.score || 0,
                        ),
                      }}
                    >
                      <Typography
                        variant="h2"
                        fontWeight={500}
                        color={handleScoreColor(report.data.graph?.score || 0)}
                      >
                        {report.data.graph?.score || 0}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ width: "100%" }}
                      justifyContent="center"
                    >
                      <NpsProgressBox
                        score={report.data.graph?.detractors.percentual || 0}
                        color="#fd6643"
                        radius="5px 0 0 5px"
                      />
                      <NpsProgressBox
                        score={report.data.graph?.passives.percentual || 0}
                        color="#FEC230"
                      />
                      <NpsProgressBox
                        score={report.data.graph?.promoters.percentual || 0}
                        color="#6EAA2E"
                        radius="0 5px 5px 0"
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              )}
            </Card>
          </Grid>
          <Grid xs={12} lg={8}>
            <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
              <Card>
                <CardContent sx={{ pb: "16px !important" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Chip
                      label="Tarefas"
                      variant="outlined"
                      color="secondary"
                    />
                    <Box sx={{ flex: 1 }}>
                      <Stack direction="row" spacing={2}>
                        <RRTaskNotify />
                        <RRTaskLog taskLog={{ set: setLogID, get: logID }} />
                        <RRDeliveredTasks
                          taskLog={{ set: setLogID, get: logID }}
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>

              <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
                <NumCard
                  title="Clientes"
                  loading={graph.state}
                  number={graph.total}
                  percent={100}
                  action={
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        navigate("/organizations");
                      }}
                    >
                      Ver todos
                    </Button>
                  }
                />
                <NumCard
                  title="Empresas"
                  loading={graph.state}
                  number={graph.empresas.total}
                  percent={graph.empresas.percentual}
                  a1={graph.empresas.a1}
                  action={<BusinessIcon fontSize="large" color="primary" />}
                />
                <NumCard
                  title="Igrejas"
                  loading={graph.state}
                  number={graph.igrejas.total}
                  percent={graph.igrejas.percentual}
                  a1={graph.igrejas.a1}
                  action={<ChurchIcon fontSize="large" color="primary" />}
                />
                <NumCard
                  title="Save"
                  loading={graph.state}
                  number={graph.save.total}
                  percent={graph.save.percentual}
                  a1={graph.save.a1}
                  action={
                    <MonetizationOnIcon fontSize="large" color="primary" />
                  }
                />
                <NumCard
                  title="Terceiro Setor"
                  loading={graph.state}
                  number={graph.terceiroSetor.total}
                  percent={graph.terceiroSetor.percentual}
                  a1={graph.terceiroSetor.a1}
                  action={
                    <AccountBalanceIcon fontSize="large" color="primary" />
                  }
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Dashboard;
