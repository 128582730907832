import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, LinearProgress, Grid } from "@mui/material";

import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";

import Profile from "./profile";
import Tabs from "./tabs";
import History from "./history";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";

export const OrganizationContext = createContext<{
  data?: any;
  set?: any;
  reload?: any;
}>({
  data: {},
});

export const useOrganization = () => {
  return useContext(OrganizationContext);
};

const Organization = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [organization, setOrganization] = useState<any>([]);
  const [reload, setReload] = useState<any>(null);

  const init = async () => {
    try {
      const organization = await fetchData(
        `/organizations/${params.organizationID}`,
        {
          method: "GET",
        },
      );
      console.log(organization);
      setOrganization(organization);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  const A1ExpiricyAlert = () => {
    if (!organization.digital_certificate?.expiricy) return <></>;

    const now = new Date();
    const expiricy = new Date(organization.digital_certificate?.expiricy);
    const verify = new Date(organization.digital_certificate?.expiricy);
    verify.setMonth(verify.getMonth() - 2);

    if (now < verify) return <></>;

    return (
      <Alert severity={now > expiricy ? "error" : "warning"} sx={{ mb: 2 }}>
        O certificado digital deste cliente vence{now > expiricy && "u"} em{" "}
        <b>{formatDate(organization.digital_certificate?.expiricy)}</b>.
      </Alert>
    );
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.organizationID]);

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <OrganizationContext.Provider
      value={{
        data: organization,
        set: setOrganization,
        reload: { state: reload, set: setReload },
      }}
    >
      <ViewContent
        title={organization.name}
        icon={<DomainOutlinedIcon sx={{ mr: 2 }} />}
      >
        <div>
          <A1ExpiricyAlert />
          {loading && <LinearProgress sx={{ mt: 1 }} />}
          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Profile />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Tabs />
                <History />
              </Grid>
            </Grid>
          )}
        </div>
      </ViewContent>
    </OrganizationContext.Provider>
  );
};

export default Organization;
