import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";

type FormValues = {
  info: string;
  type: string;
  adiantamento: string;
};

const OrganizationDpTab = () => {
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [progress, setProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      info: organization.dp?.info || "",
      type: organization.dp?.type || "",
      adiantamento: organization.dp?.adiantamento || "",
    },
  });

  async function onSubmit(data: any) {
    setProgress(true);
    const payload = { ...organization, dp: data };

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      set(payload);

      setIsEditing(false);
      snackbar?.open(
        "Informações contábeis atualizadas com sucesso!",
        "success",
      );
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("info")}
              label="FOPAG com Informação?"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("type")}
              label="Tipo de FOPAG"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("adiantamento")}
              label="Adiantamento"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
        </Grid>

        {auth?.user.permission_level === "manager" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              justifyContent={isEditing ? "flex-end" : "flex-start"}
            >
              {!isEditing && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                </Button>
              )}
              {isEditing && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {!progress && "Salvar"}
                    {progress && (
                      <CircularProgress size={12} sx={{ color: "#FFF" }} />
                    )}
                  </Button>
                </>
              )}
            </Stack>
          </>
        )}
      </form>
    </Box>
  );
};

export default OrganizationDpTab;
