import { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Alert,
  Link,
  Stack,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";

import { humanFileSize } from "../../../../utils";

const drawerDataDefault = {
  state: false,
  delete: false,
  data: {
    id: "",
    name: "",
    size: 0,
    lastModifiedFormated: "",
    key: "",
  },
};

const OrganizationCloudTab = () => {
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [drawer, setDrawer] = useState(drawerDataDefault);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>({
    folders: [],
    files: [],
  });

  const handleCloudApi = async () => {
    setLoading(true);
    setError(false);

    try {
      const result = await fetchData(`/cloud/${organization._id}?path=cloud`, {
        method: "GET",
      });

      console.log(result);
      setData(result);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  const handleError = (msg: string) => {
    snackbar?.open(msg, "error");
  };

  const handleDownload = async (key: string, name: string) => {
    try {
      fetchData(
        `/cloud/download?key=${key}`,
        {
          method: "GET",
        },
        false,
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.download = name;

          document.body.appendChild(link);

          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          document.body.removeChild(link);
        })
        .catch((error) => {
          handleError("Ops! No momento não foi possível baixar o arquivo.");
        });
    } catch (error) {
      handleError("Ops! No momento não foi possível baixar o arquivo.");
    }
  };

  const handleDelete = async (key: string) => {
    try {
      fetchData(
        `/cloud?key=${key}`,
        {
          method: "DELETE",
        },
        false,
      )
        .then((response) => {
          setDrawer(drawerDataDefault);
          handleCloudApi();
          snackbar?.open("Arquivo excluído com sucesso!", "success");
        })
        .catch((error) => {
          handleError("Ops! Não foi possível excluir o arquivo.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível excluir o arquivo.");
    }
  };

  const handleLinkCopy = async (key: string) => {
    try {
      fetchData(`/cloud/share?key=${key}`, {
        method: "GET",
      })
        .then((response) => {
          navigator.clipboard.writeText(response.url);
          snackbar?.open("Link copiado com sucesso!", "success");
        })
        .catch((error) => {
          handleError("Ops! Não foi possível gerar o link.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível gerar o link.");
    }
  };

  useEffect(() => {
    handleCloudApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!drawer.state) {
      setDrawer(drawerDataDefault);
    }
  }, [drawer]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          // <Link
          //   onClick={(e) => {
          //     e.preventDefault();
          //     handleDownload(params.row.key, params.row.name);
          //   }}
          //   sx={{
          //     overflow: "hidden",
          //     justifyContent: "left",
          //     cursor: "pointer",
          //   }}
          // >
          <Stack
            direction="row"
            spacing={1}
            color="primary.main"
            sx={{ cursor: "default" }}
          >
            <InsertDriveFileIcon />
            <Typography variant="body2">{params.row.name}</Typography>
          </Stack>
          // </Link>
        );
      },
    },
    {
      field: "lastModifiedFormated",
      headerName: "Última modificação",
      minWidth: 170,
    },
    {
      field: "size",
      headerName: "Tamanho",
      renderCell: (params: any) => humanFileSize(params.row.size),
    },
  ];

  const handleRowClick = (params: any) => {
    setDrawer({ ...drawer, state: true, data: params.row });
  };

  return (
    <Box sx={{ p: 2 }}>
      {loading && <LinearProgress />}

      {!data.files.length && !loading && (
        <Alert severity="info">Nenhum arquivo enviado até o momento.</Alert>
      )}

      {data.files.length > 0 && !loading && (
        <DataGrid
          rows={data.files}
          columns={columns}
          onRowClick={handleRowClick}
          autoHeight
          hideFooter
          sx={{ border: "none", marginTop: "-10px" }}
        />
      )}

      <Drawer
        anchor="right"
        open={drawer.state}
        onClose={() => {
          setDrawer({ ...drawer, state: false });
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <InsertDriveFileIcon />
              <Typography
                variant="body2"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                {drawer.data.name}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setDrawer({ ...drawer, state: false });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack direction="column" spacing={2}>
            <Button
              variant="outlined"
              fullWidth
              size="small"
              onClick={() => {
                handleDownload(drawer.data.key, drawer.data.name);
              }}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              size="small"
              onClick={() => {
                handleLinkCopy(drawer.data.key);
              }}
            >
              Copiar Link
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">Detalhes do arquivo</Typography>
          <br />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Tipo
            </Typography>
            <Typography variant="caption">
              {drawer.data.name?.split(".").pop()?.toLocaleUpperCase()}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Tamanho
            </Typography>
            <Typography variant="caption">
              {humanFileSize(drawer.data.size)}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Modificado
            </Typography>
            <Typography variant="caption">
              {drawer.data.lastModifiedFormated}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="column">
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              ID
            </Typography>
            <Typography variant="caption">
              {drawer.data.id.replace(/['"]+/g, "")}
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />
          {!drawer.delete && auth?.user.permission_level === "manager" && (
            <Button
              variant="outlined"
              color="error"
              fullWidth
              size="small"
              onClick={() => {
                setDrawer({ ...drawer, delete: true });
              }}
            >
              Apagar arquivo
            </Button>
          )}
          {drawer.delete && (
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" fontWeight="bold" textAlign="center">
                Confirme a exclusão do arquivo?
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  size="small"
                  onClick={() => {
                    setDrawer({ ...drawer, delete: false });
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  size="small"
                  onClick={() => {
                    handleDelete(drawer.data.key);
                  }}
                >
                  Confirmar
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default OrganizationCloudTab;
