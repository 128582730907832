import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Chip, Stack, Link, Button } from "@mui/material";

import { fetchData } from "../../../fetchData";
import { navigateToOrganization } from "../../../utils/navigateToOrganization";

const Persons = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [persons, setPersons] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/users?is_admin=false&sort=name&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setPersons(
          result.rows.map((person: any) => ({
            id: person._id,
            name: [person._id, person.name],
            status: person.status ? "Ativo" : "Inativo",
            client: person.client,
            phone: person.phone,
            email: person.email,
            login: person.login,
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`/persons/${params.value[0]}`);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.value[1]}
          </Link>
        );
      },
      minWidth: 150,
      sortComparator: (v1: any, v2: any) => v1[1].localeCompare(v2[1]),
    },
    { field: "status", headerName: "Situação", width: 100 },
    {
      field: "client",
      headerName: "Organização",
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="row" spacing={1}>
              {params.value.map((value: any) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigateToOrganization(value, navigate);
                  }}
                />
              ))}
            </Stack>
          );
        } else {
          return "";
        }
      },
      minWidth: 150,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="row" spacing={1}>
              {params.value.map((value: any) => {
                if (value.value) {
                  return (
                    <Chip
                      icon={
                        value.label == "mobile" ? (
                          <WhatsAppIcon />
                        ) : (
                          <PhoneIcon />
                        )
                      }
                      key={value.value}
                      label={value.value}
                      size="small"
                    />
                  );
                } else {
                  return "";
                }
              })}
            </Stack>
          );
        } else {
          return "";
        }
      },
      minWidth: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="column" spacing={1}>
              {params.value.map((value: any) => {
                if (value.value) {
                  return (
                    <Link
                      href={`mailto:${value.value}`}
                      key={value.value}
                      sx={{ mb: -1, p: 0 }}
                    >
                      {value.value}
                    </Link>
                  );
                } else {
                  return "";
                }
              })}
            </Stack>
          );
        } else {
          return "";
        }
      },
      minWidth: 150,
      sortable: false,
    },
    { field: "login", headerName: "Login", minWidth: 150 },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent title="Pessoas" icon={<GroupIcon sx={{ mr: 2 }} />}>
      <DataGrid
        rows={persons}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
    </ViewContent>
  );
};

export default Persons;
