import axios from "axios";

const token = localStorage.getItem(`${process.env.REACT_APP_KEYNAME}token`);
const headers = {
  Authorization: "",
  "Content-Type": "application/json",
};

if (token) {
  headers.Authorization = `Bearer ${token}`;
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers,
});

export default api;
