import { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Alert,
  AlertTitle,
  Drawer,
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  LinearProgress,
  CircularProgress,
  TextField,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { fetchData } from "../../../fetchData";
import {
  userProfile,
  organizationsProfileLabels,
  contactLabels,
} from "../../../utils/labels";

import { useSnackbar } from "../../../contexts/SnackbarContext";

type FormValues = {
  name: string;
  login: string;
  profile: string;
  permission_level: string;
  email: string;
  status: boolean;
};

const UsersDrawer = (props: any) => {
  const snackbar = useSnackbar();
  const { drawer, setDrawer, id, data: reload } = props;
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      login: "",
      profile: "",
      permission_level: "",
      email: "",
      status: true,
    },
  });

  const init = async () => {
    try {
      const user = await fetchData(`/users/${id}`, {
        method: "GET",
      });
      setUser(user);
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (id) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user._id) {
      setValue("name", user.name);
      setValue("login", user.login);
      setValue("permission_level", user.permission_level || "");
      setValue("profile", user.profile.length ? user.profile[0] || "" : "");
      setValue("email", user.email.length ? user.email[0]?.value || "" : "");
      setValue("status", user.status ? user.status.toString() : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function onSubmit(data: any) {
    setProgress(true);

    data.status = data.status === "true";
    data.profile = [data.profile];
    data.email = [
      {
        value: data.email,
        primary: true,
        label: "work",
      },
    ];

    try {
      await fetchData(`/users/${id}`, {
        method: "PUT",
        body: JSON.stringify({ ...user, ...data }),
      });

      reload(25, 0);
      setDrawer(false);
      snackbar?.open("Usuário atualizado com sucesso!", "success");
    } catch (error) {
      console.log(error);
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <Drawer
      anchor="right"
      open={drawer}
      onClose={() => {
        setDrawer(false);
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 300 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="body2"
            sx={{ width: 170, wordWrap: "break-word", flex: 1 }}
          >
            Editar usuário
          </Typography>
          <IconButton
            onClick={() => {
              setDrawer(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        {error && (
          <Alert
            severity="error"
            sx={{
              mt: 1,
              flexDirection: "column",
              alignContent: "center",
              textAlign: "center",
              ".MuiAlert-icon": {
                margin: "0 auto",
              },
            }}
          >
            <AlertTitle>Ops!</AlertTitle>
            Um erro inesperado ocorreu. Tente novamente mais tarde.
          </Alert>
        )}
        {loading && <LinearProgress />}
        {!error && !loading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("name", {
                required: "Informe o nome do usuário.",
              })}
              label="Nome"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors?.name ? true : false}
            />
            {errors?.name && (
              <Alert severity="error">{errors.name.message?.toString()}</Alert>
            )}

            <TextField
              {...register("login", {
                required: "Informe o login de acesso do usuário.",
              })}
              label="Usuário de acesso"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors?.login ? true : false}
            />
            {errors?.login && (
              <Alert severity="error">{errors.login.message?.toString()}</Alert>
            )}

            <TextField
              {...register("email", {
                required: "Informe o e-mail do usuário.",
              })}
              label="E-mail"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors?.email ? true : false}
            />
            {errors?.email && (
              <Alert severity="error">{errors.email.message?.toString()}</Alert>
            )}

            <Divider sx={{ my: 2 }} />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Perfil de acesso"
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                >
                  {userProfile.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="permission_level"
            />
            {errors?.permission_level && (
              <Alert severity="error">
                {errors.permission_level.message?.toString()}
              </Alert>
            )}

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Carteira"
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                >
                  {organizationsProfileLabels.map((label: string) => (
                    <MenuItem key={label} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="profile"
            />

            <Divider sx={{ my: 2 }} />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Situação"
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem key="true" value="true">
                    Ativo
                  </MenuItem>
                  <MenuItem key="false" value="false">
                    Inativo
                  </MenuItem>
                </TextField>
              )}
              control={control}
              name="status"
            />

            <Divider sx={{ my: 2 }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {!progress && "Salvar"}
                {progress && (
                  <CircularProgress size={12} sx={{ color: "#FFF" }} />
                )}
              </Button>
            </Stack>
          </form>
        )}
      </Box>
    </Drawer>
  );
};

export default UsersDrawer;
