import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import SegmentIcon from "@mui/icons-material/Segment";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Chip, Stack, Link, Button } from "@mui/material";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";
import { useDialog } from "../../../contexts/DialogContext";

import Segment from "../Segment";

const Segments = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [segments, setSegments] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/segments?sort=created_at&order=desc&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setSegments(
          result.rows.map((segment: any) => ({
            id: segment._id,
            title: segment.title,
            filters: segment.filters,
            created_at: segment.created_at,
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              dialog?.open(
                "Editar Segmento",
                <Segment
                  id={params.row.id}
                  onSave={(_id) => {
                    console.log(_id);
                  }}
                  onDelete={() => {
                    console.log("apagou!");
                    data(12, 0);
                  }}
                />,
              );
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      field: "filters",
      headerName: "Nº de Filtros",
      flex: 1,
      renderCell: (params: any) => {
        return params.value.length;
      },
      minWidth: 150,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Criado em",
      flex: 1,
      renderCell: (params: any) => {
        return formatDate(params.value);
      },
      minWidth: 150,
      sortable: false,
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent title="Segmentos" icon={<SegmentIcon sx={{ mr: 2 }} />}>
      <DataGrid
        rows={segments}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
    </ViewContent>
  );
};

export default Segments;
