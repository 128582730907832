import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Button,
  Typography,
} from "@mui/material";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";
import { npsStatusSwitch } from "../../../utils/labels";
import { useAuth } from "../../../contexts/AuthContext";

const Nps = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/surveys/nps?limit=${limit}&skip=${skip}&order=desc`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setSurveys(
          result.rows.map((row: any) => ({
            id: row._id,
            title: row.title,
            option: row.option,
            status: row.status,
            created_at: formatDate(row.created_at, "D/M/Y H:i"),
            published_up: formatDate(row.published_up, "D/M/Y H:i"),
            published_down: formatDate(row.published_down, "D/M/Y H:i"),
            sended_at: formatDate(row.sended_at, "D/M/Y H:i"),
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Pesquisa",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <Link
              onClick={(e) => {
                e.preventDefault();

                navigate(
                  `/surveys/nps/${
                    params.row.status === "editing" &&
                    auth?.user.permission_level === "manager"
                      ? "edit/"
                      : ""
                  }${params.id}`,
                );
              }}
              sx={{
                overflow: "hidden",
                justifyContent: "left",
                cursor: "pointer",
              }}
            >
              {params.row.title}
            </Link>
            <Chip
              color={params.row.status != "editing" ? "success" : "default"}
              label={npsStatusSwitch(params.row.status)}
              size="small"
              sx={{ textTransform: "uppercase", ml: 1 }}
            />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Data",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Stack direction="column">
            <>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {params.row.status === "editing" && "Criado em"}
                {params.row.status === "done" && "Concluído em"}
                {params.row.status === "sended" && "Enviado em"}
              </Typography>
              <Typography variant="body2" component="div">
                {params.row.status === "editing" && params.row.created_at}
                {params.row.status === "done" && params.row.published_down}
                {params.row.status === "sended" && params.row.sended_at}
              </Typography>
            </>
          </Stack>
        );
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent title="Pesquisas NPS" icon={<DonutSmallIcon sx={{ mr: 2 }} />}>
      <DataGrid
        rows={surveys}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
    </ViewContent>
  );
};

export default Nps;
