import { useState, useEffect } from "react";
import {
  DataGrid as XDataGrid,
  DataGridProps,
  GridPaginationModel,
  ptBR,
} from "@mui/x-data-grid";

interface Props extends DataGridProps {
  rows: any;
  data?: (limit: number, skip: number) => void;
  loading: boolean;
  columns: any;
  rowCount: number;
  paginationModel: GridPaginationModel;
}

const DataGrid = (props: Props) => {
  const [paginationModel, setPaginationModel] = useState(props.paginationModel);

  useEffect(() => {
    if (props.data) {
      props.data(
        paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  return (
    <XDataGrid
      rows={props.rows}
      loading={props.loading}
      columns={props.columns}
      rowCount={props.rowCount}
      pageSizeOptions={[props.paginationModel.pageSize]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      // onCellClick={(params)=>{console.log(params)}}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  );
};

DataGrid.defaultProps = {
  paginationModel: {
    page: 0,
    pageSize: 25,
  },
};

export default DataGrid;
