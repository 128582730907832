import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Link } from "@mui/material";

import { fetchData } from "../../../fetchData";
import UsersDrawer from "./drawer";
import { useAuth } from "../../../contexts/AuthContext";

const Users = () => {
  const params = new URL(document.location.toString()).searchParams;
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [userID, setUserID] = useState<any>(null);
  const [users, setUsers] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const readOnly = auth?.user.permission_level === "operator" ? true : false;

  const handleRowClick = (params: any) => {
    setDrawer(true);
  };

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/users?is_admin=true&sort=name&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setUsers(
          result.rows.map((user: any) => ({
            id: user._id,
            name: user.name,
            status: user.status ? "Ativo" : "Inativo",
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    const id = params.get("id");

    if (id) {
      setUserID(id);
      setDrawer(true);
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        if (readOnly) {
          return params.value;
        }

        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setUserID(params.row.id);
              setDrawer(true);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Link>
        );
      },
      minWidth: 150,
    },
    { field: "status", headerName: "Situação", width: 100 },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Usuários"
      icon={<AssignmentIndOutlinedIcon sx={{ mr: 2 }} />}
    >
      <DataGrid
        rows={users}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
      {userID && (
        <UsersDrawer
          drawer={drawer}
          setDrawer={setDrawer}
          id={userID}
          data={data}
        />
      )}
    </ViewContent>
  );
};

export default Users;
