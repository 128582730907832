import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Chip, Stack, Link, Button } from "@mui/material";

import { fetchData } from "../../../fetchData";

const Organizations = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setLoading(true);

    try {
      const result = await fetchData(
        `/organizations?sort=name&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setOrganizations(
          result.rows.map((organization: any) => ({
            id: organization._id,
            reference: Number(organization.reference),
            name: organization.name,
            status: organization.status ? "Ativo" : "Inativo",
            profile: organization.profile,
          })),
        );
        setLoading(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const columns = [
    {
      field: "reference",
      headerName: "Cód.",
      width: 100,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`/organizations/${params.id}`);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.row.name}
          </Link>
        );
      },
      minWidth: 150,
    },
    { field: "profile", headerName: "Carteira", width: 300 },
    { field: "status", headerName: "Situação", width: 100 },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Organizações"
      icon={<DomainOutlinedIcon sx={{ mr: 2 }} />}
    >
      <DataGrid
        rows={organizations}
        data={data}
        loading={loading}
        columns={columns}
        rowCount={rowCount}
        autoHeight
      />
    </ViewContent>
  );
};

export default Organizations;
