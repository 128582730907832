import { useState } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";

import MailIcon from "@mui/icons-material/Mail";
import AddCommentIcon from "@mui/icons-material/AddComment";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";
import DragAndDropFiles from "../../../../components/DragAndDropFiles";
import DigitalCertificate from "./actions/digitalCertificate";

const OrganizationActionsTab = () => {
  const [cloud, setCloud] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [note, setNote] = useState("");
  const snackbar = useSnackbar();
  const auth = useAuth();
  const organization = useOrganization();

  const handleSaveNote = async () => {
    try {
      await fetchData("/histories", {
        method: "POST",
        body: JSON.stringify({
          isComment: true,
          user: auth?.user._id,
          username: auth?.user.name,
          content: note,
          type: "organization",
          type_id: organization.data._id,
        }),
      });
      organization.reload.set(new Date());
      snackbar?.open("Nota adicionada com sucesso!", "success");
      setNote("");
      setIsComment(false);
    } catch (error) {
      snackbar?.open(
        "Ops! Ocorreu um erro ao adicionar a nota. Tente mais tarde.",
        "error",
      );
    }
  };

  return (
    <>
      {!isComment && !cloud && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ m: 2 }}
        >
          <Button
            size="small"
            color="quaternary"
            variant="outlined"
            onClick={() => {
              setIsComment(true);
            }}
          >
            <AddCommentIcon sx={{ mr: 1 }} /> Nova anotação
          </Button>
          {auth?.user.permission_level === "manager" && (
            <>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setCloud(true);
                }}
              >
                <FilterDramaIcon sx={{ mr: 1 }} /> Enviar arquivo
              </Button>
              <DigitalCertificate />
            </>
          )}
          {/* <Button size="small" color="tertiary" variant="outlined">
            <MailIcon sx={{ mr: 1 }} /> Enviar Comunicado
          </Button> */}
        </Stack>
      )}
      {cloud && (
        <Box sx={{ p: 2 }}>
          <DragAndDropFiles
            baseUrl={`cloud/${organization.data._id}?path=cloud`}
            allowedFiles=".pdf"
          />
          <Box sx={{ textAlign: "center", pt: 2 }}>
            <Button
              size="small"
              variant="outlined"
              sx={{ color: "text.secondary" }}
              onClick={() => {
                setCloud(false);
              }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      )}
      {isComment && (
        <Box sx={{ p: 2, backgroundColor: "note.main" }}>
          <TextField
            id="standard-multiline-static"
            multiline
            fullWidth
            rows={4}
            variant="standard"
            color="quaternary"
            hiddenLabel
            autoFocus
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Box sx={{ textAlign: "right", pt: 2 }}>
            <Button
              size="small"
              sx={{ color: "text.secondary" }}
              onClick={() => {
                setIsComment(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              color="quaternary"
              type="submit"
              onClick={() => handleSaveNote()}
              disabled={note === "" ? true : false}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrganizationActionsTab;
